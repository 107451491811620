import React, { Component } from 'react'
import BlogCard from "../components/cards/blog"
import { Link } from 'gatsby'

class SimilarArticles extends Component {

  render() {
    let posts = this.props.data
    return (
      <section className="similar-articles">
        <div className="similar-articles__inner container">
          <div className="similar-articles__content">
            <div className="similar-articles__top">
              <h3>Latest Articles</h3>
              <Link className="btn btn--border" to="/insights">View All</Link>
            </div>
            <div className="similar-articles__blogcards">
              { posts.edges.map((el, i) => <BlogCard key={i} {...el.node} />) }
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SimilarArticles
