import React, { Component } from 'react'


class Introduction extends Component {

  render() {
    let { introduction } = this.props
    return (
      <section className="introduction">
        <div className="introduction__inner container">
          <div className="introduction__info" dangerouslySetInnerHTML={{ __html: introduction }} />
        </div>
      </section>
    )
  }
}

Introduction.defaultProps = {
  introduction: ``,
}

export default Introduction