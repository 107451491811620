import React from 'react'

import Introduction from '../components/introduction'
import Content from '../components/content'
import Image from '../components/image'
import ImageGallery from '../components/image-gallery'
import DoubleImage from '../components/double-image'

const RenderPostBlock = (param, el, i) => {
  let block = {
    'post_Acf_PostBlocks_Introduction': (el, i) => (<Introduction {...el} key={i} />),
    'post_Acf_PostBlocks_Content': (el, i) => (<Content {...el} key={i} />),
    'post_Acf_PostBlocks_Image': (el, i) => (<Image {...el} key={i} />),
    'post_Acf_PostBlocks_ImageGallery': (el, i) => (<ImageGallery {...el} key={i} />),
    'post_Acf_PostBlocks_DoubleImage': (el, i) => (<DoubleImage {...el} key={i} />),
  }[param]

  if (!block) return

  return block(el, i)
}

export default RenderPostBlock
