import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import Fade from 'react-reveal/Fade'

import Cta from "../components/cta"
import SimilarArticles from "../components/similar-articles"
import Seo from '../components/seo'

import RenderPostBlock from '../utils/render-post-block'

class ArticleTemplate extends Component {
  render() {
    let post = this.props.data.wpPost
    const shareLink = this.props.location.href
    let props = {
      target: '_blank',
      rel: 'noopener noreferrer'
    }
    return (
      <>
        <Seo title={he.decode(post.seo.title)} bodyClass={post.slug} description={post.seo.metaDesc} />
        <section className="introduction">
          <div className="introduction__inner container">
            <div className="introduction__heading">
              <Fade bottom distance="30px">
                <h1>{post.title}</h1>
              </Fade>
            </div>
            <div className="introduction__author">
              <Fade bottom distance="30px">
                <h6>{ post.acf.author }</h6>
              </Fade>
            </div>
          </div>
        </section>
        { post.acf.postBlocks && post.acf.postBlocks.map((el, i) => {
          return RenderPostBlock(el.fieldGroupName, el, i)
        }) }
        <section className="content content--share">
          <div className="content__inner container">
            <div className="content__content">
              <Fade bottom distance="30px">
                <h6>SHARE ON <a href={`//www.facebook.com/sharer/sharer.php?u=${shareLink}`} {...props}>FACEBOOK</a> / <a href={`//twitter.com/home?status=${shareLink}`} {...props}>TWITTER</a> / <a href={`//www.linkedin.com/shareArticle?mini=true&url=${shareLink}&title=${he.decode(post.title)}`} {...props}>LINKEDIN</a></h6>
              </Fade>
            </div>
          </div>
        </section>
        <SimilarArticles data={this.props.data.posts} />
        <Cta title={`<p>So tell us, what is *it you’re after? <span>We look forward to learning what *it is that will make you happier.</span></p>`} buttonLink='/contact-us/' buttonText='Connect' />
      </>
    )
  }
}

export const postQuery = graphql`
  query ($id: String!) {
    wpPost(id: {eq: $id}) {
      title
      slug
      categories {
        nodes {
          name
          slug
        }
      }
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        author
        postBlocks {
          ... on WpPost_Acf_PostBlocks_Introduction {
            fieldGroupName
            introduction
          }
          ... on WpPost_Acf_PostBlocks_Content {
            fieldGroupName
            content
          }
          ... on WpPost_Acf_PostBlocks_Image {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  fixed (width: 1000) {
                    src
                  }
                }
              }
            }
          }
          ... on WpPost_Acf_PostBlocks_DoubleImage {
            fieldGroupName
            leftImage {
              localFile {
                childImageSharp {
                  fixed (width: 1000) {
                    src
                  }
                }
              }
            }
            rightImage {
              localFile {
                childImageSharp {
                  fixed (width: 1000) {
                    src
                  }
                }
              }
            }
            leftImageHover {
              localFile {
                childImageSharp {
                  fixed (width: 1000) {
                    src
                  }
                }
              }
            }
            rightImageHover {
              localFile {
                childImageSharp {
                  fixed (width: 1000) {
                    src
                  }
                }
              }
            }
          }
          ... on WpPost_Acf_PostBlocks_ImageGallery {
            fieldGroupName
						images {
              localFile {
                childImageSharp {
                  fixed (width: 1000) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    posts: allWpPost(limit: 3, sort: {fields: [date], order: DESC}, filter:{id:{ne:$id}}) {
      edges {
        node {
          title
          slug
          categories {
            nodes {
              name
              slug
            }
          }
          excerpt
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fixed (width: 800) {
                    src
                  }
                }
              }
            }
          }
          acf {
            readTime
          }
        }
      }
    }
  }
`

export default ArticleTemplate
