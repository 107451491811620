import React, { Component } from 'react'

class Image extends Component {

  render() {
    let { image } = this.props
    return (
      <section className="image">
        <div className="image__inner container">
          { image && <img src={image.localFile.childImageSharp.fixed.src} alt="Seiva - Insights" /> }
        </div>
      </section>
    )
  }
}

Image.defaultProps = {
  title: `<p>Great advice starts with a great conversation.<br><span>So, what it* is you’re aiming for?</span></p>`,
}

export default Image
