import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'


class Content extends Component {

  render() {
    let { content } = this.props
    return (
      <section className="content">
        <Fade bottom distance="30px">
        <div className="content__inner container">
          <div className="content__content">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
        </Fade>
      </section>
    )
  }
}

Content.defaultProps = {
  content: `<p>It’s so important to open up to people that you trust. Take some of the weight off your chest and relief a bit of stress. It’s not an easy thing to do for some, but no one needs to feel isolated and alone in business or in life. I turn to my mentors during tough times and have also sought out a psychologist to help keep my head in the right space.</p>`,
}

export default Content